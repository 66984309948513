<template>    
    <b-container>
    <b-overlay rounded="sm" :show="show">
        <b-card title="Documentos compartidos">
            <table class="table table-responsive" v-if="documents.length>0">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Fichero</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="doc in documents">
                        <td>{{ doc.name }}</td>
                        <td><a v-if="doc.file" :href="$http.defaults.baseURL + 'storage/app/private/' + doc.file.download_link " target="_blank" >
                            <!-- {{ doc.file.original_name }} -->
                            Descargar
                            </a>                        
                        </td>
                    </tr>                    
                </tbody>
            </table>
            <div v-else>No hay documentos</div>
        </b-card>
    </b-overlay>
    </b-container>
</template>
<script>
import { BContainer, BRow, BCard, BOverlay } from 'bootstrap-vue';
import { mapState } from 'vuex';
export default {
    components: { BContainer, BRow, BCard, BOverlay },
    data() {
        return {
            documents: [],
            show: true
        }
    },
    created() {
        this.show = true;
        this.$http.get("/api/getTechDocs?user_id=" + this.user.id).then((response) => {
            this.documents = response.data.documents;
            for(let doc of this.documents){
                doc.file = doc.file ? JSON.parse(doc.file)[0] : null;
            }
            this.show = false;
        })
    },
    computed: {
        ...mapState({
        user: state => state.auth.user,   
        })
    }
}
</script>
<style scoped lang="scss" >   
    a{
        color: #007bff;
        text-decoration: underline;
    }
</style>